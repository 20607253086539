import axios from "axios";
import { bankerAPI } from "@/api/config";
import { reportError } from "@/utils/error-reporting";
import { ProjectBankLoanModel } from "@/models";

async function getBankLoans({
    queries = {},
    page = 1,
    perPage = 15,
    include = [
        "appUser",
        "projectPurchase.agent",
        "projectUnit",
        "projectPurchaseReservation"
    ]
}) {
    try {
        const response = await axios.get(`${bankerAPI}/bank-loans`, {
            params: {
                ...queries,
                page: page,
                limit: perPage,
                include: include.join(",")
            }
        });

        return response.data;
    } catch (error) {
        reportError(error, `[Banker CP] Get Bank Loans`);
        throw error;
    }
}

async function getBankLoan(id) {
    try {
        let include = [
            "appUser",
            "projectPurchase.agent",
            "projectUnit",
            "project",
            "loanApplicationDocuments",
            "projectPurchaseReservation",
            "resubmitLoanApplicationDocuments"
        ];
        const response = await axios.get(
            `${bankerAPI}/bank-loans/${id}?include=${include.join(",")}`
        );

        return response.data;
    } catch (error) {
        reportError(error, `[Banker CP] Get Bank Loan (id: ${id})`);
        throw error;
    }
}

async function verifyLead(id) {
    try {
        const response = await axios.put(
            `${bankerAPI}/bank-loans/${id}/submitted`
        );
        return response.data;
    } catch (error) {
        reportError(error, `[Banker CP] Verify Lead (id: ${id})`);
        throw error;
    }
}

async function declineLead(id, payload) {
    try {
        payload = ProjectBankLoanModel.putDeclinePayload(payload);
        const response = await axios.put(
            `${bankerAPI}/bank-loans/${id}/request-additional-doc`,
            payload
        );
        return response.data;
    } catch (error) {
        reportError(error, `[Banker CP] Decline Lead (id: ${id})`);
        throw error;
    }
}
async function approveLA(id, payload) {
    try {
        payload = ProjectBankLoanModel.putApproveLAPayload(payload);
        const response = await axios.put(
            `${bankerAPI}/bank-loans/${id}/approved`,
            payload
        );
        return response.data;
    } catch (error) {
        reportError(error, `[Banker CP] Approve LA (id: ${id})`);
        throw error;
    }
}

async function rejectLA(id, payload) {
    try {
        payload = ProjectBankLoanModel.putRejectPayload(payload);
        const response = await axios.put(
            `${bankerAPI}/bank-loans/${id}/rejected`,
            payload
        );
        return response.data;
    } catch (error) {
        reportError(error, `[Banker CP] Reject LA (id: ${id})`);
        throw error;
    }
}

async function confirmLOSigned(id, payload) {
    try {
        const response = await axios.put(
            `${bankerAPI}/bank-loans/${id}/signed-lo`,
            payload
        );
        return response.data;
    } catch (error) {
        reportError(error, `[Banker CP] Confirmed LO Signed (id: ${id})`);
        throw error;
    }
}

export default {
    getBankLoans,
    getBankLoan,
    verifyLead,
    declineLead,
    approveLA,
    rejectLA,
    confirmLOSigned
};

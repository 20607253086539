import axios from "axios";
import { API, bankerAPI } from "@/api/config";
import { reportError } from "@/utils/error-reporting";

async function getProfile() {
    try {
        const response = await axios.get(`${bankerAPI}/banker-profile`);

        return response.data;
    } catch (error) {
        reportError(error, `[Banker CP] Get Profile`);
        throw error;
    }
}

async function updateProfile(payload) {
    try {
        const response = await axios.put(
            `${bankerAPI}/banker-profile`,
            payload
        );

        return response.data;
    } catch (error) {
        reportError(error, `[Banker CP] Update Profile`);
        throw error;
    }
}

async function updatePassword(payload) {
    try {
        const response = await axios.put(
            `${bankerAPI}/banker-profile/password`,
            payload
        );

        return response.data;
    } catch (error) {
        reportError(error, `[Banker CP] Update Password`);
        throw error;
    }
}

async function forgetPassword(email) {
    try {
        const response = await axios.post(`${API}/reset-password`, {
            type: "banker",
            email: email
        });

        return response.data;
    } catch (error) {
        reportError(error, `[Banker CP] Forget Password`);
        throw error;
    }
}

export default {
    getProfile,
    updateProfile,
    updatePassword,
    forgetPassword
};

import { convertNullIfEmpty } from "@/utils/null-check";

export class ProfileModel {
    static putProfilePayload(e) {
        return {
            name: e.name || "",
            avatar: e.avatar || [],
            bankBranchName: e.bankBranchName || "",
            bankBranchAddress1: e.bankBranchAddress1 || "",
            bankBranchAddress2: e.bankBranchAddress2 || "",
            bankAreaId: e.bankAreaId || "",
            bankCityId: e.bankCityId || "",
            bankStateId: e.bankStateId || "",
            bankContactNumber: e.bankContactNumber || "",

            mobileISOCode: convertNullIfEmpty(e.phoneNo.mobileISOCode) || "",
            mobileCountryCode:
                convertNullIfEmpty(e.phoneNo.mobileCountryCode) || "",
            mobileNumber: convertNullIfEmpty(e.phoneNo.mobileNumber) || ""
        };
    }
    static getToEdit(e) {
        return {
            email: e.user?.email,
            bank: e.bank,

            name: convertNullIfEmpty(e.name) || "",
            avatar: e.avatar || [],
            bankBranchName: convertNullIfEmpty(e.bankBranchName) || "",
            bankBranchAddress1: convertNullIfEmpty(e.bankBranchAddress1) || "",
            bankBranchAddress2: convertNullIfEmpty(e.bankBranchAddress2) || "",
            bankAreaId: e.bankArea ? e.bankArea.id : "",
            bankCityId: e.bankCityId ? e.bankCity.id : "",
            bankStateId: e.bankStateId ? e.bankState.id : "",
            bankContactNumber: e.bankContactNumber || "",
            area: e.bankArea ?? "",
            city: e.bankCity ?? "",
            state: e.bankState ?? "",

            mobileISOCode: e.user.mobileISOCode || "",
            mobileCountryCode: e.user.mobileCountryCode || "",
            mobileNumber: e.user.mobileNumber || ""
        };
    }
}
